
@mixin styling($screenSize,$fontSize,$innerBox) {
  .displayWrap{
    margin-top: $fontSize*2;
    margin-bottom:$fontSize*3 ;
    :global {
      .ant-carousel .slick-dots li button {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-top: 60px;
      }
      .ant-carousel .slick-dots li {
        width: 16px!important;
        height: 8px!important;
      }
      .slick-track {
        display: flex;
        align-items: center;
      }
    }
  }

  .renovation {
    :global {
      .ant-back-top {
        position: fixed;
        right: 20px;
        bottom: 156px;
      }
    }
  }

  .renovationTitle{
    background: url("assets/renovationTitle.png") no-repeat;
    max-height: 250px;
    background-size: 100% ;
    text-align: center;
    padding-bottom:$screenSize*0.076;
    padding-top:$screenSize*0.076;
  }
  .renovationWords{
    font-size: $fontSize;
    color: #FFFFFF;
  }
  .renovationCases{
    margin-top: $fontSize;
    margin-bottom: $fontSize;
    margin-left: 50%;
    transform: translateX( -50%);
    width:$innerBox;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }
  .caseBody{
    width:$screenSize*0.225;
    margin-bottom: $fontSize;
    opacity: 0.8;
    cursor: pointer;
    &:hover{
      width:$screenSize*0.229;
      transition: 0.6s ease;
      .amplification {
        opacity: 1;
      }
    }
  }

  .caseImg{
    width: inherit;
    height:$screenSize*0.15;
  }
  .caseDimBack{
    background: #000000;
    width: inherit;
    position: relative;
    .amplification {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 62px;
      height: 62px;
      opacity: 0;
    }
  }
  .caseBar{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background: #393939;
    color:#FFFFFF;
    padding: $fontSize/2 $fontSize;
  }
  .barText{
    font-size: $fontSize/2;
  }
  .noOfImg{
    font-size: $fontSize/3;
  }
  .sliderSize{
    //width: $innerBox/2;
    margin-left: 50%;
    min-height: 590px;
    max-height: 724px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .slider{
    width: $innerBox;
    margin-left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .topNav{
    opacity: 0.9;
    margin-left: 50%;
    margin-top: 100px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    font-size: 16px;
    color: #FFFFFF;
    font-weight: normal;
    line-height: 48px;
    width: $innerBox;
  }
}

@media screen and (min-width: 800px) and (max-width: 1439px) {
  @include styling($screenSize: 1440px,$fontSize:25px,$innerBox:1200px);
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  @include styling($screenSize: 1600px,$fontSize:28px, $innerBox:1400px);
}
@media screen and (min-width: 1600px) {
  @include styling($screenSize: 1600px,$fontSize:32px,$innerBox:1520px);
}
@media screen and (min-width: 1920px) {
  @include styling($screenSize: 1600px,$fontSize:32px,$innerBox:1520px);
  .renovationTitle{
    width: 1920px;
  }
}

.navigationWrapper {
  position: relative;
}

.backButton{
  &:hover{
    opacity: 0.6;
  }
}
.arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
  opacity: 0.5;
  &:hover{
    opacity: 1;
  }
}
.arrowLeft{
  left: 5px;
}

.arrowRight{
  left: auto;
  right: 5px;
}

.closeOutlinedIcon {
  color: #cccccc;
  opacity: 0.5;
  font-size: 26px;
  float: right;
  margin-top: 30px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.goUp :global{
  .ant-fade-enter{
    //animation: floatForGoUp 0.5s;
    animation-fill-mode: forwards;
  }

}
@keyframes floatForGoUp {
  0% {right: -10px; opacity: 0 }
  100% {right: 20px; opacity: 1}
}

.designBackImg{
  background-size: 100% 100%;
  background-image: url("assets/designBack.png");
  background-repeat: no-repeat;
}


.caseBodyM{
  width:165px;
  margin-bottom: 10px;
  opacity: 0.8;
  cursor: pointer;
  &:hover{
    width:165px;
    transition: 0.6s ease;
    .amplification {
      opacity: 1;
    }
  }
  .barText{
    color: #ffffff;
    font-weight: 600;
    width: 115px;
    font-size: 6px;
  }
  .noOfImg{
    font-size: 8px;
  }
}

.caseImgM{
  width: 165px;
  height:100px;
}
.caseDimBackM{
  background: #000000;
  width: 100px;
  position: relative;
}
.caseBarM{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: #393939;
  color:#FFFFFF;
  padding: 5px 5px;

}
.renovationCasesM{
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 50%;
  transform: translateX( -50%);
  width:85%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
