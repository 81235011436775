.banner {
  position: relative;
  overflow: hidden;
  min-width: 1200px;
  .bannerTitle{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bannerSubtitle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    opacity: 0;
  }

  .bannerMainTitle {
    animation: bannerMainTitle 1s;
  }

  .bannerSubTitleText {
    animation: bannerSubtitle 1s linear 1s 1 forwards;
  }

  @keyframes bannerMainTitle {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

  @keyframes bannerSubtitle {
    0% { opacity: 0 }
    99% { opacity: 1 }
    100% { opacity: 1 }
  }

  :global {
    .ant-carousel .slick-dots li button {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-top: 0;
    }
    .ant-carousel .slick-dots li {
      width: 16px!important;
      height: 8px!important;
    }
    .ant-carousel .slick-slide {
      position: relative;
      height: 100vh;
      div {
        height: calc(100% + 1px);
      }
    }
    .ant-carousel .slick-dots-bottom {
      bottom: 32%;
    }
  }
}

.bannerM {
  position: relative;
  overflow: hidden;
  min-width: 340px;
  .bannerImgM{
    width: 100%;
  }
  .bannerTitle{
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bannerSubtitle{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    opacity: 0;
  }

  .bannerMainTitle {
    animation: bannerMainTitle 0.5s;
  }

  .bannerSubTitleText {
    animation: bannerSubtitle 1s linear 1s 1 forwards;
  }

  @keyframes bannerMainTitle {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

  @keyframes bannerSubtitle {
    0% { opacity: 0 }
    99% { opacity: 1 }
    100% { opacity: 1 }
  }

  :global {
    .ant-carousel .slick-dots li button {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-top: 0;
    }
    .ant-carousel .slick-dots li {
      width: 16px!important;
      height: 8px!important;
    }
    .ant-carousel .slick-slide {
      position: relative;
      //height: 100vh;
      div {
        height: calc(100% + 1px);
      }
    }
    .ant-carousel .slick-dots-bottom {
      bottom: 32%;
    }
  }
}
.bannerImg {
  width: 100%;
  height: 100%;
}

.layout, .banner {
  background: linear-gradient(180deg, #121212 0%, #151515 81%, #000000 100%);
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  min-width: 1300px;
}
.layoutMobile{
  background: linear-gradient(180deg, #121212 0%, #151515 81%, #000000 100%);
}

.layoutMobileMask {
  overflow: hidden;
  height: 100vh;
}

.layoutMobileInfo {
  pointer-events: none;
  opacity: 0.3;
}

.contentMobile{
  //max-width: 475px;
}

.content {
  max-width: 1920px;
  min-width: 1300px;
}

.tooltipM {
  width: 100%;
}

.tooltip {
  padding-top: 0px;
  max-width: 100%;
  :global {
    .ant-tooltip-inner {
      padding: 0;
    }
    .ant-tooltip-content {
      margin-top: -6px;
      background-color: #1C1C1C;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  }
}

.tooltipTitle {
  div {
    cursor: pointer;
    line-height: 40px;
    font-size: 15px;
    padding: 0 59px 0 32px;
    color: rgba(255, 255, 255, 0.5);
  }
  div:hover {
    color: #FFFFFF;
    background-color: #4D4D4D;
  }
}

.tooltipTitleM {
  text-align: center;
  div {
    cursor: pointer;
    line-height: 40px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(216, 216, 216, 0.7);
  }
  div:hover {
    color: #FFFFFF;
    background-color: #4D4D4D;
  }
}

.navHover:hover{
  background-color: #4D4D4D;
}

.anchor {
  height: 74px;
  background-color: rgba(0,0,0,0.5);
  z-index: 666;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 70px;
  float: left;
  .tooltipDiv {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global {
    .ant-anchor {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 17px;
    }
    .ant-btn {
      height: 100%;
    }
    .ant-anchor-link-title, .ant-btn-link {
      color:  #FFFFFF;
      opacity: 0.5 ;
      font-size: 17px;
    }
    .ant-anchor-ink::before {
      width: 0!important;
    }
    .ant-anchor-link-title-active {
      color: #FFFFFF;
      opacity: 1;
    }
    .ant-anchor-ink-ball {
      display: none;
    }
  }
}

.anchorBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.anchorTop {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1920px;
}


.copyRight{
  width: 100%;
  background: #000000;
  opacity: 0.3;
  text-align: center;
}

@mixin styling($screenSize,$fontSize,$innerBox) {
  .footerIcon{
    width: 30px;
  }
  .footerWrap {
    width: 100%;
    background: linear-gradient(180deg, #FF0000 0%, #E00404 100%);
    padding-top:$screenSize*0.01;
  }
  .footerContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $innerBox;
    margin-bottom: $screenSize*0.01;
    margin-left: 50%;
    transform: translateX( -50%);
  }
  .borderDiv {
    width: 1px;
    height: 80px;
    background: #FFFFFF;
    opacity: 0.5;
  }
  .imgBorder {
    margin-left: 15px;
    margin-right: 15px;
    width: 10px;
    height: 2px;
    background: #FFFFFF;
    margin-top: 11px;
  }
  .footerTitleWrap{
    width:$screenSize*0.3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footerTitle{
    width:$screenSize*0.5;
    margin-left: 15px;
    font-size: $fontSize+6;
    color: #FFFFFF;
    line-height: 25px;
    margin-top: 11px;
  }
  .footerInfo{
    font-size:$fontSize+4;
    color: #FFFFFF;
    line-height: 27px;
    span {
      cursor: pointer;
    }
  }
  .copyRightWrap{
    text-align: center;
  }
  .copyRightContext{
    font-size:$fontSize;
    color: #FFFFFF;
    line-height: 24px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1400px) {
  @include styling($screenSize: 1300px, $fontSize: 10px, $innerBox: 1100px);
}

@media screen and (min-width: 1401px) and (max-width: 1439px) {
  @include styling($screenSize: 1440px, $fontSize: 10px, $innerBox: 1200px);
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  @include styling($screenSize: 1600px,$fontSize:13.5px, $innerBox:1300px);
}
@media screen and (min-width: 1600px) {
  @include styling($screenSize: 1600px,$fontSize:13.5px,$innerBox:1400px);
}

@media screen and (min-width: 1920px) {
  .anchorBottom {
    width: 1922px;
  }
  .banner {
    width: 1918px;
  }
  .content {
    margin: 0 auto;
    width: 100%;
  }
}



.footerWrapMobile{
  width:100%;
  background: linear-gradient(180deg, #FF0000 0%, #E00404 100%);
  padding-top:2%;
}

.footerInfoM{
  font-size:9px;
  color: #FFFFFF;
  //margin-bottom: 2pt;
  div {
    cursor: pointer;
  }
}
.footerContentMobile{
  // width:375px;
}
.copyRightContextM{
  font-size:10px;
  color: #FFFFFF;
}

.mobileNavi{
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 50px;
	line-height: 50px;
  text-align: center;
  color:#FFFFFF;
  background: #000000;
}

.menuOutlined {
  position: fixed;
  top: 8px;
  right: 18px;
  font-size: 23px;
  color:#FFFFFF;
  z-index: 99;
}

:global {
  .react-images__header {
    opacity: 1!important;
    left: 0!important;
    right: unset!important;
    transform: unset!important;
    -webkit-transform: unset!important;
    background: none!important;
    svg {
      width: 26px;
      height: 26px;
    }
  }
  .react-images__footer {
    opacity: 1!important;
    position: unset!important;
    padding: 10px 0 0 0!important;
    justify-content: center!important;
    transform: unset!important;
    -webkit-transform: unset!important;
  }
}