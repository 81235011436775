.contactUsWrap {
  background: #F5F5F5;
  padding-bottom: 30px;
}

.contactImg {
  margin-left: 50%;
  transform: translateX(-50%);
}

.formTable {
  margin-top: 2%;
  margin-bottom: 5%;
  width: 600px;
  margin-left: 10%;
}

.submitBar :global {
  display: flex;
  justify-content: space-between;
  width: 32%;
}
.submitBar :global .ant-btn {
  width: 83px;
}


.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #ad0c0c;
  position: absolute;
  border-radius: 50%;
}


/*# sourceMappingURL=index.module.css.map */
