.contactUsWrap{
  background: #35363A;
  padding-bottom: 30px;
}
.contactUsWrapMobile{
  background: #35363A;
  padding-bottom: 30px;
}

.contactImg{
  margin-left: 50%;
  transform: translateX(-50%);
}
.inputStyle :global{
  border: none;
  background-color: #202124;
  color:#FFFFFF;
}

.inputStyleM :global{
  border: none;
  background-color: #202124;
  color:#FFFFFF;
  // width: 220px;
}

.popupDate :global{
  .ant-picker-panel-container{
    background-color: #202124;
  }
  .ant-picker-header{
    color: #FFFFFF;
  }
  .ant-picker-content th{
    color: #FFFFFF;
  }
  .ant-picker-content td{
    color: #FFFFFF;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid #EB5E00;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background: #EB5E00;
  }
  .ant-picker-cell:hover {
    .ant-picker-cell-inner{
      background: rgba(235, 94, 0, 0.2)!important;
    }
  }

  .ant-picker-today-btn{
    color: #FFFFFF;
  }
  .ant-picker-header button{
    color: #FFFFFF;
  }
}
@mixin styling($screenSize,$fontSize,$innerBox) {

  .nameItem{
    width: 65%;
  }
  .inputStyle2 :global{
    border: none;
    background-color: #202124;
    color:#FFFFFF ;
    width: 100%;
    .ant-picker-input > input{
      color:#FFFFFF ;
    }

  }
  .formTable{
    margin-top: 2%;
    margin-bottom: 1%;
    width: $screenSize/3.5;
    margin-left: 10%;
  }
  .submitBar :global{
    display: flex;
    justify-content: space-between;
    width: $screenSize*0.12;
    .ant-btn{
      width: 83px;
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1439px) {
  @include styling($screenSize: 1440px,$fontSize:12px,$innerBox:1200px);
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  @include styling($screenSize: 1600px,$fontSize:13.5px, $innerBox:1400px);
}
@media screen and (min-width: 1600px) {
  @include styling($screenSize: 1600px,$fontSize:13.5px,$innerBox:1600px);
}

.contactBackImg{
  background-size: 100% 100%;
  background-image: url("assets/contacTitle.png") ;
  background-repeat: no-repeat;
}

.mobileContactButton{
  border: 1px solid #979797;
  background-color: #1C1C1C;
  color: #FFFFFF;
 &:hover{
   color: #FFFFFF;
   background-color: #1C1C1C;
 }
  &:focus{
   color: #FFFFFF;
   background-color: #1C1C1C;
 }
}

.mobileModal :global{
  .ant-modal-content{
    background-color: #35363A;
  }
  .ant-modal-header{
    background-color: #35363A;
  }
  .ant-modal-close{
    color: #FFFFFF;
  }
  .ant-form .ant-form-item .ant-form-item-label {
    max-width: 25%!important;
  }
  .ant-form .ant-form-item .ant-form-item-control {
    max-width: 75%!important;
  }
}