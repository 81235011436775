.hot {
  min-width: 1300px;
}

.title {
  color: #FFFFFF;
  font-size: 30px;
  font-weight: normal;
  text-align: center;
  line-height: 250px;
  background-image: url('./assets/titleBg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.imgList {
  padding: 50px 0 0 110px;
  height: calc(100vh - 250px - 139px);
  margin-bottom: 50px;
  min-height: 363px;
  .imgDIv {
    position: relative;
    .imgBtn {
      position: absolute;
      bottom: 14px;
      right: 22px;
      opacity: 0;
    }
    img {
      cursor: pointer;
      width: 300px;
    }
    :global {
      .ant-btn {
        width: 48px;
        height: 24px;
        padding: 0;
        font-size: 12px;
      }
      .ant-btn-primary:hover, .ant-btn-primary:focus{
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
      }
    }
  }
  .imgDIv:hover {
    animation: toTop 0.5s;
    animation-fill-mode: forwards;
    border-radius: 7px;
    box-shadow: 0 5px 10px 0 rgba(216, 216, 216, 1);
    img {
      opacity: 0.7;
    }
    .imgBtn {
      opacity: 1;
    }
  }
}

.imgListM{
  padding: 40px calc((100vw - 300px)/2) 20px calc((100vw - 300px)/2);
  min-height: calc(135vh);
  .imgDIvM {
    img {
      cursor: pointer;
      width: 300px;
    }
    :global {
      .ant-btn {
        width: 48px;
        height: 24px;
        padding: 0;
        font-size: 12px;
      }
      .ant-btn-primary:hover, .ant-btn-primary:focus{
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
      }
    }
  }
  .imgDIvM:hover {
    animation: toTop 0.5s;
    animation-fill-mode: forwards;
    border-radius: 7px;
    box-shadow: 0 5px 10px 0 rgba(216, 216, 216, 1);
    img {
      opacity: 0.7;
    }
    .imgBtn {
      opacity: 1;
    }
  }
}
@keyframes toTop {
  0% { top: 0 }
  100% { top: -5px }
}

.hotModal {
  min-width: 1300px;
  .modalDiv {
    padding-top: 80px;
    display: flex;
    justify-content: center;
  }
  .mainImg {
    width: 595px;
    height: 842px;
    margin-right: 51px;
  }

  .thumbnail {
    cursor: pointer;
    .thumbnailDiv {
      background-color: rgba(234, 234, 234, 1);
      width: 189px;
      padding: 10px;
      margin-bottom: 28px;
      img {
        width: 100%;
      }
      &:hover{
        background: rgba(234, 234, 234, 0.85 );;
      }
    }

  }
  :global {
    .ant-modal-body, .ant-modal-content {
      padding: 0;
      background-color: rgba(234, 234, 234, 0);
      box-shadow: none;
    }
  }
}

.closableIcon {
  width: 40px;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 9999;
  cursor: pointer;
}

.content {
  padding: 33px 0 54px 0;
  .list:hover {
    background-image: url("./assets/bgHover.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .list {
    padding: 28px 90px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .text {
      font-size: 14px;
      padding-right: 90px;
      h3 {
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
      }
      p {
        line-height: 25px;
        color: #FFFFFF;
        word-break: break-all;
        word-wrap: break-word;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      span {
        color: #FFFFFF;
        opacity: 0.8;
      }
    }
    img {
      width: 263px;
      height: 156px;
    }
  }
}

.borderDiv {
  width: 84%;
  height: 1px;
  border-bottom: 1px solid #cccccc;
  margin-left: 90px;
  opacity: 0.2;
}

.bottom {
  cursor: pointer;
  height: 56px;
  line-height: 56px;
  background: rgba(255, 255, 255, 0.02);
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  opacity: 0.7;
  img {
    margin: 0 0 3px 6px;
    width: 16px;
  }
}

.details {
  margin: 91px 90px 0 90px;
  .info {
    color: #FFFFFF;
    h1 {
      margin: 40px 0 34px 0;
      font-size: 32px;
      color: #FFFFFF;
    }
    h2 {
      text-align: right;
      margin: 66px 0 46px 0;
      font-size: 16px;
      color: #FFFFFF;
      opacity: 0.6;
    }
    img {
      width: 100%;
      height: 750px;
      margin-bottom: 34px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      opacity: 0.8;
    }
  }
  .breadcrumb {
    color: #FFFFFF;
    cursor: pointer;
  }
  :global {
    .ant-breadcrumb-separator {
      color: #FFFFFF;
    }
  }
}

.rLettersBackImg{
  background-repeat: no-repeat;
  background-image: url("assets/recommandLettersBack.png");
  background-size: 100% 100%;
}