@mixin styling($screenSize, $fontSize, $innerBox) {
    .fatherBox {
        width: $innerBox;
        margin-left: 50%;
        transform: translateX( -50%);
        position: relative;
        margin-top: 100px;
         :global {
            .ant-btn:hover,
            .ant-btn:focus {
                background: #000000;
                opacity: 0.8;
                color: #FFFFFF;
            }
        }
    }
    .blankStyle {
        opacity: 0;
    }
    .topBannerWrap {
        padding: $screenSize*0.05 $screenSize*0.095;
        opacity: 0;
        position: relative;
    }
    .titleText {
        position: absolute;
        top: $screenSize*0.145;
        margin-left: $screenSize*0.067;
        z-index: 9999;
        p {
            margin: 0;
        }
    }
    .bannerImg {
        width: $screenSize*0.81;
    }
    .floatToRight {
        animation: floatToRight 1s;
        animation-fill-mode: forwards;
    }
    .floatToLeft {
        animation: floatToLeft 1s;
        animation-fill-mode: forwards;
    }
    .whatTitle {
        width: $screenSize*0.17;
        margin-left: 10px;
    }
    .whoTitle {
        width: $screenSize*0.139;
    }
    .whenTitle {
        margin-top: 10%;
        width: $screenSize*0.18;
    }
    .whereTitle {
        width: $screenSize*0.17;
    }
    .howTitle {
        width: $screenSize*0.128;
    }
    .leftBoxWrap {
        width: $screenSize*0.42;
        margin-top: $screenSize*0.13;
        position: relative;
        opacity: 0;
        z-index: 99;
    }
    .leftBoxWrapForWhere {
        width: $screenSize*0.42;
        margin-top: $screenSize*0.2;
        position: relative;
        opacity: 0;
        z-index: 99;
    }
    .rightBoxWrap {
        width: $screenSize*0.42;
        position: relative;
        opacity: 0;
    }
    .lastBoxWrap {
        width: $screenSize*0.42;
        position: relative;
        margin-top: $screenSize*0.45;
        margin-left: auto;
        opacity: 0;
    }
    .whoContent {
        background: url("assets/mobilePics/interiorBg.png") no-repeat;
        // background-size: 100%;
        display: flex;
        align-items: center;
    }
    .whoContentWrap {
        padding: $screenSize*0.02 $screenSize*0.1 $screenSize*0.014;
    }
    .whoContentWords1 {
        font-size: $fontSize+6;
        text-align: justify;
        //word-break: break-all;
        //word-wrap: break-word;
        color: #FFFFFF;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .whoContentWords3 {
        font-size: $fontSize;
        text-align: justify;
        //word-break: break-all;
        //word-wrap: break-word;
        color: #FFFFFF;
        width: $screenSize*0.219;
        font-weight: 600;
    }
    .whoContentWords2 {
        width: $screenSize*0.221;
        text-align: justify;
        //word-break: break-all;
        //word-wrap: break-word;
        margin-bottom: 0;
        font-size: $fontSize;
        color: #FFFFFF;
        font-weight: 600;
    }
    .whoContentIcon {
        margin-right: 3px;
        width: $screenSize*0.026;
        height: $screenSize*0.07;
        float: left;
        margin-top: 5px;
    }
    .contactWrap {
        padding-left: $screenSize*0.006;
        padding-bottom: $screenSize*0.015;
    }
    .whatContent {
        background: url("assets/WhatBackground2x.png") no-repeat;
        background-size: 100% 100%;
    }
    .whatContentPhotos {
        padding-top: $screenSize*0.119;
        padding-right: $screenSize*0.126;
        padding-bottom: $screenSize*0.013;
    }
    .whatPhoto {
        width: $screenSize*0.287;
        height: $screenSize*0.17;
        position: relative;
        overflow: hidden;
        img {
            cursor: pointer;
        }
        .firstImg {
            width: 90%;
            position: absolute;
            top: 50%;
            bottom: 0;
            transform: translateY(-50%);
            z-index: 66;
        }
        .twoImg {
            width: 78%;
            position: absolute;
            top: 50%;
            bottom: 0;
            right: 20px;
            transform: translateY(-50%);
            z-index: 55;
        }
        .lastImg {
            width: 70%;
            position: absolute;
            top: 50%;
            bottom: 0;
            right: 0;
            transform: translateY(-50%);
            z-index: 44;
        }
    }
    .bannerSwitch {
        animation: bannerFloatToRight 1s;
        z-index: 77!important;
    }
    .lastSwitch {
        animation: bannerFloatToLast 1s;
    }
    .lastEnterSwitch {
        animation: bannerFloatToLastEnter 1s;
    }
    .firstSwitch {
        animation: bannerFloatToLeft 0.5s;
    }
    .programWrap {
        padding-left: $screenSize*0.02;
        padding-bottom: $screenSize*0.04;
    }
    .whatButton {
        background: url("assets/button1.png") no-repeat;
        background-size: 100% 100%;
        &:hover {
            background-size: 100% 100%;
            background: url("assets/button1Hover.png") no-repeat;
        }
    }
    .whatButton, .whatEngButton {
        z-index: 3333;
        margin-right: $screenSize*0.06;
        width: $innerBox*0.09;
        height: $innerBox*0.025;
        &:hover {
            cursor: pointer;
            background-size: 100% 100%;
        }
    }
    .whatEngButton {
        background: url("assets/engBtn.png") no-repeat;
        background-size: 100% 100%;
        width: $innerBox*0.09;
        height: $innerBox*0.025;
        &:hover {
            background: url("assets/engBtnHover.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .whenContent {
        background: url("assets/whenBackground.png") no-repeat;
        background-size: 100%;
    }
    .outBoxWrapperPosition {
        position: absolute;
        top: $screenSize*0.6;
        width: 100%;
    }
    .whenIntroWrap {
        padding: $screenSize*0.18 $screenSize*0.174 $screenSize*0.072 $screenSize*0.052;
    }
    .whereContent {
        background: url("assets/whereBackground.png") no-repeat;
        background-size: 100%;
    }
    .whereContentWrap {
        display: flex;
        justify-content: space-between;
        padding: $screenSize*0.15 $screenSize*0.03 $screenSize*0.02 $screenSize*0.046;
    }
    .mapWrap {
        width: $screenSize*0.186;
        height: $screenSize*0.114;
    }
    .addressTitle {
        color: #FFFFFF;
        font-size: $fontSize+4;
        font-weight: 600;
    }
    .address {
        font-size: $fontSize;
        color: #FFFFFF;
        margin-bottom: 0;
        width: $screenSize*0.131;
        font-weight: 600;
    }
    .whereButton {
        margin-top: 20px;
        margin-left: 30px;
        width: $innerBox*0.09;
        height: $innerBox*0.025;
        background: url("assets/button2.png") no-repeat;
        background-size: 100% 100%;
        &:hover {
            cursor: pointer;
            background: url("assets/button2Hover.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .whereEngButton {
        margin-top: 20px;
        margin-left: 30px;
        width: $innerBox*0.09;
        height: $innerBox*0.025;
        background: url("assets/whereEngBtn.png") no-repeat;
        background-size: 100% 100%;
        &:hover {
            cursor: pointer;
            background: url("assets/whereEngBtnHover.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .howContent {
        background: url("assets/howBackground.png") no-repeat;
        background-size: 100% 100%;
        min-height: 500px;
        margin-bottom: 140px;
    }
    .howLinks {
        font-size: $fontSize;
        color: #FFFFFF;
        opacity: 0.8;
        &:hover {
            opacity: 0.5;
            color: #FFFFFF;
        }
    }
    .howContentWrap {
        padding-top: 30px;
        text-align: center;
    }
    .programWrap {
        margin-left: $screenSize*0.03;
    }
    .contactTitle {
        font-size: $fontSize+2;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 0;
    }
    .contact {
        font-weight: bold;
        font-size: $fontSize;
        color: #FFFFFF;
        margin-bottom: 0;
        span {
            cursor: pointer;
        }
    }
    .introTitle {
        font-size: $fontSize+4;
        color: #FFFFFF;
        text-align: justify;
        //word-break: break-all;
        //word-wrap: break-word;
        margin-bottom: 0;
        font-weight: 600;
    }
    .introInfo {
        font-size: $fontSize;
        color: #FFFFFF;
        width: $screenSize*0.21;
        font-weight: 600;
    }

    .whoContentWords1M{
        font-size: 1px;
    }

    .whoEng{
        margin-top: -65px;
    }
    .whoCn{
        margin-top: 0;
    }
    .whenEng{
        margin-top: 40px;
    }
    .whenCn{
        margin-top: 0;
    }
}

@media screen and (max-width: 800px) {
    @include styling($screenSize: 375px, $fontSize: 12px, $innerBox: 1200px);
}

@media screen and (min-width: 800px) and (max-width: 1400px) {
    @include styling($screenSize: 1300px, $fontSize: 10px, $innerBox: 1100px);
}

@media screen and (min-width: 1401px) and (max-width: 1439px) {
    @include styling($screenSize: 1440px, $fontSize: 12px, $innerBox: 1200px);
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
    @include styling($screenSize: 1600px, $fontSize: 13.5px, $innerBox: 1400px);
}

@media screen and (min-width: 1600px) {
    @include styling($screenSize: 1600px, $fontSize: 13.5px, $innerBox: 1470px);
}

.howContentWrapTitle {
    padding-bottom: 60px;
}

.whoCertificate {
    margin-left: -48px;
    &:hover {
        opacity: 0.8;
        //margin-left: -50px;
        //transition: ease 0.5s;
    }
}

.whoTitleImg {
    text-align: right;
    padding: 21px 15px 0 29px;
    img {
        width: 100%;
    }
}

.about {
    position: relative;
}

.goUp {
    position: fixed;
    right: 20px;
    bottom: 156px;
    cursor: pointer;
    animation: floatForGoUp 0.5s;
}

@keyframes floatForGoUp {
    0% {
        right: -10px;
        opacity: 0
    }
    100% {
        right: 20px;
        opacity: 1
    }
}

.howImgDiv {
    width: 490px;
    height: 330px;
    margin: 0 auto;
    padding-left: 10px;
    img {
        width: 150px;
    }
}

.pagination {
    margin: 0 auto;
    width: 100%;
    span {
        display: inline-block;
        margin-left: 1.5%;
        margin-right: 1.5%;
        width: 8px;
        height: 8px;
        background-color: #fff;
        //margin: 0 6px;
        border-radius: 50%;
        cursor: pointer;
    }
}

.topBannerWrap {
    width: 100%;
}

@keyframes floatToRight {
    0% {
        left: -100px;
        opacity: 0
    }
    100% {
        left: 0;
        opacity: 1
    }
}

@keyframes floatToLeft {
    0% {
        right: -100px;
        opacity: 0
    }
    100% {
        right: 0;
        opacity: 1
    }
}

@keyframes bannerFloatToRight {
    0% {
        right: 5%
    }
    100% {
        right: 10%;
        width: 90%
    }
}

@keyframes bannerFloatToLast {
    0% {
        right: 0;
        width: 70%
    }
    100% {
        right: 20px;
        width: 78%
    }
}

@keyframes bannerFloatToLastEnter {
    0% {
        right: 10px;
        width: 70%;
        opacity: 1
    }
    100% {
        right: 0;
        width: 70%;
        opacity: 1
    }
}

@keyframes bannerFloatToLeft {
    0% {
        left: 0;
        opacity: 0.6
    }
    100% {
        left: -100px;
        opacity: 0
    }
}

.outBoxWrapper {
    display: flex;
    justify-content: space-between;
    z-index: 22;
}

//@media screen and(max-width:1400px) {
//    .leftBoxWrap {
//        margin-top: 200px;
//        margin-left: 0;
//    }
//    .outBoxWrapperPosition {
//        position: static!important;
//    }
//    .fatherBox {
//        margin-top: 10px;
//    }
//    .outBoxWrapper {
//        margin-top: 10px;
//    }
//    .rightBoxWrap {
//        margin-top: 50px;
//    }
//    .lastBoxWrap {
//        margin-top: 50px;
//        //margin-left: 0;
//    }
//    .outBoxWrapper {
//        display: flex;
//        flex-wrap: wrap;
//    }
//}


.mobileAboutUsWrapper{
    width:100%;
}
.MobileCenter{
    margin-left: 3%;
}

.titleTextM {
    margin: -20px 0 0 -17%;
    z-index: 9999;
    p {
        margin: 0;
    }
}
.mobileHeader{
    margin-top: 30px;
    text-align: center;
}

.whoMobile {
    margin-top: 60pt;
}

.whatMobile{
    margin-top: 60pt;
}
.whoContentMobile{
color: #FFFFFF ;
font-size: 14px;
margin-bottom: 10px;
margin-top: 10px;
font-weight: 600;
 opacity: 0.8;
}


.whoContentM{
    width: 100%;
    color: #FFFFFF ;
    font-size: 11px;
    background: url("assets/mobilePics/interiorBg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
}
.whenMobile{
    margin-top: 60pt;
}

.whenContentM{
    width: 100%;
    color: #FFFFFF ;
    font-size: 11px;
    background: url("assets/mobilePics/whenContentMBack.png") no-repeat;
    background-size: 100% 100%;
}

.contactTitleM {
    font-size: 11pt;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 600;
}
.contactM {
    font-size: 10px;
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 0;
    span {
        cursor: pointer;
    }
}
.contactM2 {
    font-size:10px;
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 0;
    span {
        cursor: pointer;
    }
}
.contactMWhere {
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 0;
    span {
        cursor: pointer;
    }
    width: 55%;
}

.whenContentM{
    color: #FFFFFF ;
    font-size: 11px;
    font-weight: 600;
}

.whereMobile{
    margin-top: 60pt;
}


.howMobile{

    margin-top: 60pt;
}
.howContentM{
    background: url("assets/mobilePics/howBackM.png") no-repeat;
    background-size: 100%;
    margin-bottom: 60px;
    width: 100%;

}

.howContentWrapM{
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
}

.howImgDivM {
    width: 100%;
    margin: 10% auto 0;
    padding-left: 16.5%;
    img {
        width: 27%;
    }
}
.howContentWrapTitleM{
    padding-bottom: 15px;
}
.paginationM{
    margin: 0 auto;
    width: 100%;
    span {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #fff;
        margin: 0 6px;
        border-radius: 50%;
        cursor: pointer;
    }
}

.whoContentWrapM{
    padding: 5% 15% 1% 15% ;
}
.whoContentIconM{
    width: 16%;
    height:40%;

    margin-right: 3pt;
    float: left;
    margin-top: -2px;
}



.whoContentWords1M{
    font-size: 10pt;
    //text-align: justify;
    //word-break: break-all;
    //word-wrap: break-word;
    color: #FFFFFF;
    margin-top: 5%;
    font-weight: 600;
    margin-bottom: 6px;
}
.whoContentWords3M{
    text-align: justify;
    font-weight: 600;
    font-size: 7.65pt;
    margin-bottom: 0;

}

.whoContentWords2M{
    text-align: justify;
    font-weight: 600;
    font-size: 7.65pt;
    margin-bottom: 0;
    clear: both;
}
.contactWrapM p {
    line-height: 1.3;
}
.contactWrapM{

    padding-bottom: 10px;
}


.whatTitleM{
    width: 38%;
    height: 28pt;
    margin-left: 5%;
}
.whatContentM {
    background: url("assets/mobilePics/whatBackM.png") no-repeat;
    width: 100%;
    color: #FFFFFF ;
    font-size: 11px;
    background-size: 100% 100%;
}
.whatContentPhotosM {
    padding-top:19%;
    padding-right: 13%;
    padding-bottom: 0.02%;
}
.introInfoM{
width: 100%;
}
.whatPhotoM {
    width: 70%;
    height: 150pt;
    position: relative;
    overflow: hidden;
    img {
        cursor: pointer;
    }
    .firstImg {
        width: 90%;
        position: absolute;
        top: 50%;
        bottom: 0;
        transform: translateY(-50%);
        z-index: 66;
    }
    .twoImg {
        width: 78%;
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 10px;
        transform: translateY(-50%);
        z-index: 55;
    }
    .lastImg {
        width: 70%;
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 0;
        transform: translateY(-50%);
        z-index: 44;
    }
}
.bannerSwitch {
    animation: bannerFloatToRightM 1s;
    z-index: 77!important;
}
.lastSwitch {
    animation: bannerFloatToLastM 1s;
}
.lastEnterSwitch {
    animation: bannerFloatToLastEnterM 1s;
}
.firstSwitch {
    animation: bannerFloatToLeftM 0.5s;
}

@keyframes bannerFloatToRightM {
    0% {
        right: 5%
    }
    100% {
        right: 10%;
        width: 90%
    }
}

@keyframes bannerFloatToLastM {
    0% {
        right: 0;
        width: 70%
    }
    100% {
        right: 10px;
        width: 78%
    }
}

@keyframes bannerFloatToLastEnterM {
    0% {
        right: 10px;
        width: 70%;
        opacity: 1
    }
    100% {
        right: 0;
        width: 70%;
        opacity: 1
    }
}

@keyframes bannerFloatToLeftM {
    0% {
        left: 0;
        opacity: 0.6
    }
    100% {
        left: -100px;
        opacity: 0
    }
}

.programWrapM {
    padding-left: 7%;
    padding-bottom: 20pt;
}

.whatButtonM {
    background: url("assets/mobilePics/button1.png") no-repeat ;
    background-size: 100% 100%;
    &:hover {
        background: url("assets/mobilePics/button1Hover.png") no-repeat;
        background-size: 100% 100%;
    }
}

.whatEngButtonM {
    background: url("assets/engBtn.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
        background: url("assets/engBtnHover.png") no-repeat;
        background-size: 100% 100%;
    }
}

.whatButtonM, .whatEngButtonM {
    z-index: 3333;
    margin-right: 18%;
    width: 77pt;
    height: 22pt;
    &:hover {
        cursor: pointer;
        background-size: 100% 100%;
    }
}

.whereTitleM {
    width: 36%;
    margin-left: 6%;
}
.whenTitleM{
    width: 36%;
   margin-left: 5%;
}
.whenIntroWrapM {
    padding:44% 14% 12% 14%;
    text-align: justify;
    //word-break: break-all;
    //word-wrap: break-word;
}
.whereContentM{
    background: url("assets/mobilePics/whereBackMobile.png") no-repeat;
    background-size: 100%;
    width: 100%;
    color: #FFFFFF;
}

.whereContentWrapM {
    display: flex;
    justify-content: space-between;
    padding:35% 6% 3% 6%;
}

.whereButtonM{
    background: url("assets/mobilePics/button2.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
        cursor: pointer;
        background: url("assets/mobilePics/button2Hover.png") no-repeat;
        background-size: 100% 100%;
    }
}

.whereButtonM, .whereEngButtonM {
    margin-left: 35%;
    margin-top: 20%;
    z-index: 3333;
    margin-bottom: 30pt;
    width: 77pt;
    height: 22pt;
}

.whereEngButtonM{
    background: url("assets/whereEngBtn.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
        cursor: pointer;
        background: url("assets/whereEngBtnHover.png") no-repeat;
        background-size: 100% 100%;
    }
}

.whereMapM{
    width: 54%;
    height: 18vh;
}
.addressDetailM{
    margin-left: 10pt;
}
.addressTitleM{
    font-weight: 600;
    font-size: 10pt;
}
.addressM{
    font-weight: 600;
    font-size: 8pt;
}

.howTitleM{
    width: 27%;
    margin-left: 5%;
}